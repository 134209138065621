import React from "react";

import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PageTitle from "~components/PageTitle";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not Found"
      description="Oops, we couldn't find that page."
    />
    <PageTitle
      title="Not found."
      subtitle="Unfortunately, this page doesn't exist."
    />
  </Layout>
);

export default NotFoundPage;
